/** Main SASS file **/

$icons-font-path: './themes/icons';
$flags-image-path: './images';
//Florajet color
$color-florajet: #AFDB83;

//Base Framework CSS
//@import "../../../../../../../node_modules/semantic-ui-sass/semantic-ui";

@import "elements/button";
@import "elements/type";
@import "elements/flag";
@import "elements/dropdown";
@import "elements/search";
@import "elements/menu";
@import "elements/breadcrumb";
@import "elements/step";
@import "elements/card";
@import "elements/header";
@import "elements/label";
@import "elements/accessory";

body {
    position: relative;
}

a {
    color: black;
    &:hover {
        color: black;
        text-decoration: underline;
    }
}

/* CUSTOM */

.ui {
    // Card
    &.cards {
        width: 100%;
    }
    &.cards > .card, &.card{
        &.product {
            border-radius: 0;
            box-shadow: none;
            -webkit-box-shadow: none;

            > .content {
                margin-top: 20px;
                border-top: none;
            }
        }
    }

    //Button
    &.fjt.button {
        color: black;
        background-color: rgba(255, 255, 255, 0.75);
        border: 2px solid #AFDB83;
        border-radius: 0;

        &:hover, &:active {
            color: white;
            background-color: #AFDB83;
        }
        &.inverted {
            color: white !important;
            background-color: #AFDB83 !important;

            &:hover, &:active {
                color: black !important;
                background-color: rgba(255, 255, 255, 0.75) !important;
            }
        }
    }
}

.hp-banner {
    &-top, &-bottom {
        h2 {
            padding: 0 0 35px;
            font-size: 37px;
            font-weight: 700;
            color: #2C2C2C;
            position: relative;

            &:after {
                content: " ";
                position: absolute;
                left: 0;
                padding-bottom: 6px;
                width: 245px;
                border-bottom: 5px solid #afdb83;
                margin-top: 55px;
            }
        }
    }
}

// HTML Balise
h1, h2, h3, h4, h5 {
    position: relative;

    &.header:not(.divider):not(.dividing) {
        &.right {
            margin-bottom: 30px;
            &:after {
                position: absolute;
                right: 0;
                border-bottom: 5px solid #AFDB83;
            }
        }
        &.center:after {
            margin: auto;
        }
        &:after {
            content: " ";
            display: block;
            padding-bottom: 10px;
            width: 80px;
            border-bottom: 5px solid #AFDB83;
        }
    }
}

h2 {
    &.header {
        font-size: 25px;
        font-weight: 700;
        color: #2C2C2C;
    }
}

.main__content {
    min-height: calc(100vh - ( 2 * 220px) - 1px );
}

#footer {
    background-color: #F7F7F7;
    margin-top: 50px;
}

/* Large Monitor */
@media only screen and (min-width: 1600px) {
    .ui.container {
        width: 1527px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .ui.grid.container {
        width: calc( 1527px  +  2rem ) !important;
    }
    .ui.relaxed.grid.container {
        width: calc( 1527px  +  3rem ) !important;
    }
    .ui.very.relaxed.grid.container {
        width: calc( 1527px  +  5rem ) !important;
    }
}

.ui.hidded {
    display: none !important;
}
