/*-------------------
        LABEL
--------------------*/

.ui {
    // FIX Bad position of original scss
    &.card .image > &.ribbon.label,
    &.image > &.ribbon.label {
        left: -1.1em;
    }

    &.florajet.ribbon {
        background: $color-florajet;
        z-index: 1;
        > i.icon {
            background-color: white;
        }
        > span {
            display: none;
            transition: opacity 1s ease-out;
            opacity: 0;
            vertical-align: middle;
        }
        &:hover {
            > span {
                opacity: 1;
                display: inline;
            }
        }
    }
}


