/*-------------------
       HEADER
--------------------*/

/*-------------------
       Floated
--------------------*/
.ui.floated.header {
    margin-top: 15px;
}
