/** Search **/

.ui.search  {
    & > .results {
        width: 100%;
        min-width: 20em;
        max-height: 20em;
        overflow-y: auto;
        margin-top: 0;

        //&::before {
        //    bottom: auto;
        //    left: 1em;
        //    top: -0.30714286 em;
        //    right: auto;
        //    margin-left: 0;
        //    position: absolute;
        //    content: '';
        //    width: 0.71428571em;
        //    height: 0.71428571em;
        //    background: #fff;
        //    -webkit-transform: rotate(45deg);
        //    transform: rotate(45deg);
        //    z-index: 2;
        //    -webkit-box-shadow: 1px 1px 0 0 #bababc;
        //    box-shadow: 1px 1px 0 0 #bababc;
        //}
    }
}
