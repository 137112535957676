/** MENU **/

.ui {
    &.secondary.menu {
        .right.menu {
            input {
                font-size: 1.4em;
            }
        }
    }
    &.vertical.menu {
        &.full {
            width: 100%;
            a {
                color: rgba(0, 0, 0, 0.85);
            }

            .item > {
                .menu {
                    margin-top: 0;
                    padding-left: 10px;
                }
                .header:not(.ui) {
                    font-size: 14px;
                }
            }
        }
    }
}

