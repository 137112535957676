/*******************************
            Types
*******************************/

/*--------------
     Page
---------------*/

h1.ui.header {
    font-size: 3rem;
}
h2.ui.header {
    font-size: 2.5rem;
}
h3.ui.header {
    font-size: 2rem;
}
h4.ui.header {
    font-size: 1.5rem;
}
h5.ui.header {
    font-size: 1rem;
}

/* Sub Header */
h1.ui.header .sub.header {
    font-size: 1.5rem;
}
h2.ui.header .sub.header {
    font-size: 1.5rem;
}
h3.ui.header .sub.header {
    font-size: 1rem;
}
h4.ui.header .sub.header {
    font-size: 1rem;
}
h5.ui.header .sub.header {
    font-size: 0.92857143rem;
}
span.ui {
    &.mini.text {
        font-size: .4em
    }
    &.tiny.text {
        font-size: .5em
    }
    &.small.text {
        font-size: .75em
    }
    &.large.text {
        font-size: 1.5em
    }
    &.big.text {
        font-size: 2em
    }
    &.huge.text {
        font-size: 4em
    }
    &.massive.text {
        font-size: 8em
    }
}
