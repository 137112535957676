/* Pour Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Pour Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.padding-content{
    padding: 0.5em 0.5em;
}

.quantity-control{
    padding-top: 15px;
    margin-left: 0;
    .button {
        margin: 0;
    }
}

.padding-top-card-accessory {
    padding-top: 10px;
    display: flex;
    align-items: center;
    input{
        width: 40px; text-align: center;
    }
}

.padding-top-card-accessory img {
    margin-right: 10px;
}

.accessory-limit-message{
    display: none;
}

.modal-accessories{
    float: right;
    cursor: pointer;
}

#navAccessories .ui.secondary.menu {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    padding: 0;
    background-color: #f1f1f1;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

.ui .segment {
    padding: 0;
}

#navAccessories .item {
    flex: 1 1 auto;
    padding: 10px 20px;
    margin: 5px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #333;
    font-weight: 500;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

#navAccessories .item:hover {
    background-color: #AFDB83;
    color: #fff;
}

#navAccessories .item.active {
    background-color: #AFDB83;
    color: white;
    font-weight: bold;
}

#limitMessage {
    display: none;
    color: red;
    text-align: center;
}

.centered-input {
    text-align: center !important;
}

.padding-top-card-accessory img.ui.bordered.image {
    height: 50px;
}

.quantity-control {
    padding-left: 10px;
}

#sylius-order {
    img {
        height: 50px !important;
        width: auto;
    }
}
