/*-------------------
       Button
--------------------*/

.ui.button {
    line-height: inherit;
}

/*--- Standard ---*/

.ui.florajet.buttons .button,
.ui.florajet.button {
    background-color: $color-florajet;
    color: white;
    text-shadow: none;
    background-image: none;
}
.ui.florajet.button {
    -webkit-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
}
.ui.florajet.buttons .button:hover,
.ui.florajet.button:hover {
    background-color: darken($color-florajet, 5%);
    color: white;
    text-shadow: none;
}
.ui.florajet.buttons .button:focus,
.ui.florajet.button:focus {
    background-color: darken($color-florajet, 3%);
    color: white;
    text-shadow: none;
}
.ui.florajet.buttons .button:active,
.ui.florajet.button:active {
    background-color: darken($color-florajet, 7%);
    color: white;
    text-shadow: none;
}
.ui.florajet.buttons .active.button,
.ui.florajet.buttons .active.button:active,
.ui.florajet.active.button,
.ui.florajet.button .active.button:active {
    background-color: $color-florajet;
    color: white;
    text-shadow: none;
}

/* Basic */
.ui.basic.florajet.buttons .button,
.ui.basic.florajet.button {
    -webkit-box-shadow: 0 0 0 1px $color-florajet inset !important;
    box-shadow: 0 0 0 1px $color-florajet inset !important;
    color: $color-florajet !important;
}
.ui.basic.florajet.buttons .button:hover,
.ui.basic.florajet.button:hover {
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 1px darken($color-florajet, 5%) inset !important;
    box-shadow: 0 0 0 1px darken($color-florajet, 5%) inset !important;
    color: darken($color-florajet, 5%) !important;
}
.ui.basic.florajet.buttons .button:focus,
.ui.basic.florajet.button:focus {
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 1px darken($color-florajet, 3%) inset !important;
    box-shadow: 0 0 0 1px darken($color-florajet, 3%) inset !important;
    color: darken($color-florajet, 5%) !important;
}
.ui.basic.florajet.buttons .active.button,
.ui.basic.florajet.active.button {
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 1px $color-florajet inset !important;
    box-shadow: 0 0 0 1px $color-florajet inset !important;
    color: darken($color-florajet, 7%) !important;
}
.ui.basic.florajet.buttons .button:active,
.ui.basic.florajet.button:active {
    -webkit-box-shadow: 0 0 0 1px darken($color-florajet, 7%) inset !important;
    box-shadow: 0 0 0 1px darken($color-florajet, 7%) inset !important;
    color: darken($color-florajet, 7%) !important;
}
.ui.buttons:not(.vertical) > .basic.florajet.button:not(:first-child) {
    margin-left: -1px;
}

/* Inverted */
.ui.inverted.florajet.buttons .button,
.ui.inverted.florajet.button {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px $color-florajet inset !important;
    box-shadow: 0 0 0 1px $color-florajet inset !important;
    color: $color-florajet;
}
.ui.inverted.florajet.buttons .button:hover,
.ui.inverted.florajet.button:hover,
.ui.inverted.florajet.buttons .button:focus,
.ui.inverted.florajet.button:focus,
.ui.inverted.florajet.buttons .button.active,
.ui.inverted.florajet.button.active,
.ui.inverted.florajet.buttons .button:active,
.ui.inverted.florajet.button:active {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: white;
}
.ui.inverted.florajet.buttons .button:hover,
.ui.inverted.florajet.button:hover {
    background-color: darken($color-florajet, 5%);
}
.ui.inverted.florajet.buttons .button:focus,
.ui.inverted.florajet.button:focus {
    background-color: darken($color-florajet, 3%);
}
.ui.inverted.florajet.buttons .active.button,
.ui.inverted.florajet.active.button {
    background-color: darken($color-florajet, 5%);
}
.ui.inverted.florajet.buttons .button:active,
.ui.inverted.florajet.button:active {
    background-color: darken($color-florajet, 7%);
}

/* Inverted Basic */
.ui.inverted.florajet.basic.buttons .button,
.ui.inverted.florajet.buttons .basic.button,
.ui.inverted.florajet.basic.button {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5) inset !important;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5) inset !important;
    color: white !important;
}
.ui.inverted.florajet.basic.buttons .button:hover,
.ui.inverted.florajet.buttons .basic.button:hover,
.ui.inverted.florajet.basic.button:hover {
    -webkit-box-shadow: 0 0 0 1px darken($color-florajet, 5%) inset !important;
    box-shadow: 0 0 0 1px darken($color-florajet, 5%) inset !important;
    color: $color-florajet !important;
}
.ui.inverted.florajet.basic.buttons .button:focus,
.ui.inverted.florajet.basic.buttons .button:focus,
.ui.inverted.florajet.basic.button:focus {
    -webkit-box-shadow: 0 0 0 1px #darken($color-florajet, 3%) inset !important;
    box-shadow: 0 0 0 1px darken($color-florajet, 3%) inset !important;
    color: $color-florajet !important;
}
.ui.inverted.florajet.basic.buttons .active.button,
.ui.inverted.florajet.buttons .basic.active.button,
.ui.inverted.florajet.basic.active.button {
    -webkit-box-shadow: 0 0 0 1px darken($color-florajet, 5%) inset !important;
    box-shadow: 0 0 0 1px darken($color-florajet, 5%) inset !important;
    color: $color-florajet !important;
}
.ui.inverted.florajet.basic.buttons .button:active,
.ui.inverted.florajet.buttons .basic.button:active,
.ui.inverted.florajet.basic.button:active {
    -webkit-box-shadow: 0 0 0 1px darken($color-florajet, 7%) inset !important;
    box-shadow: 0 0 0 1px darken($color-florajet, 7%) inset !important;
    color: $color-florajet !important;
}
