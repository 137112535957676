/*******************************
           Breadcrumb
*******************************/

/* Link */
.ui.breadcrumb {
    a {
        color: black;
        &:hover {
            color: black;
            text-decoration: underline;
        }
    }
}
